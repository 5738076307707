import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../component/navbar";
import Footer from "../component/footer";

export default function Pricing() {
  return (
    <>
      <body className="text-base font-inter text-slate-950 dark:text-white dark:bg-slate-900">
        <Navbar />

        <section
          className="py-28 w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-center bg-no-repeat"
          id="home"
        >
          <div className="absolute inset-0 bg-slate-950/60"></div>

          <div className="container">
            <div className="grid grid-cols-1 pb-8 mt-10 text-center">
              <h3 className="mt-10 text-3xl font-medium leading-normal text-white">
                Comfortable Pricing
              </h3>
            </div>
          </div>
        </section>

        <section className="relative py-16 md:py-24">
          <div className="container">
            <div className="grid md:grid-cols-3 grid-cols-1 gap-[30px] items-center">
              {/* <div className="p-6">
                                <div className="pb-8">
                                    <h3 className="mb-6 text-xl font-medium dark:text-white">Free</h3>
                                    <div className="mb-6 dark:text-white/50">
                                        <span className="relative text-xl h6 -top-5">$</span>
                                        <span className="text-5xl font-medium h6 dark:text-white">00</span>
                                        <span className="inline-block h6 ms-1">/ month</span>
                                    </div>
                                    <p className="mb-6 text-slate-400">Free features for your business.</p>
                                    <Link to="" className="inline-block w-full px-5 py-2 text-base font-normal tracking-wide text-center align-middle transition duration-500 ease-in-out border rounded-md bg-violet-600/5 hover:bg-violet-600 border-violet-600/10 hover:border-violet-600 text-violet-600 hover:text-white">Redeem Now</Link>
                                </div>
                                <div className="border-b border-slate-200 dark:border-slate-200/5"></div>
                                <ul className="self-start pt-8">
                                    <li className="flex items-center mb-1 text-slate-400">
                                        <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                                        <span>Complete documentation</span>
                                    </li>
                                    <li className="flex items-center my-1 text-slate-400">
                                        <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                                        <span>Working materials in Figma</span>
                                    </li>
                                    <li className="flex items-center my-1 text-slate-400">
                                        <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                                        <span>100GB cloud storage</span>
                                    </li>
                                    <li className="flex items-center my-1 text-slate-400">
                                        <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                                        <span>500 team members</span>
                                    </li>
                                </ul>
                            </div> */}

              <div className="p-6 rounded-md shadow-md border border-slate-500 hover:border-violet-600">
                <div className="pb-8">
                  <h3 className="mb-6 text-xl font-medium text-slate-600">
                    Business
                  </h3>
                  <div className="mb-6 text-white/50">
                    <span className="relative text-xl h6 -top-5 text-slate-600">$</span>
                    <span className="text-5xl font-bold text-slate-600 h6">9.99</span>
                    <span className="inline-block h6 ms-1 text-slate-600">/ month</span>
                  </div>
                  <p className="mb-6 text-white">
                    Basic features for your place
                  </p>
                  <Link
                    to="/signup"
                    className="inline-block w-full px-5 py-2 text-base font-normal tracking-wide text-center text-white align-middle transition duration-500 ease-in-out bg-violet-600 border border-violet-600 rounded-md hover:bg-violet-700 hover:border-violet-700"
                  >
                    Started Now
                  </Link>
                </div>
                <div className="border-b border-slate-200/10"></div>
                <ul className="self-start pt-8">
                  <li className="flex items-center mb-1 text-slate-500">
                    <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                    <span>Quick Billing</span>
                  </li>
                  <li className="flex items-center my-1 text-slate-500">
                    <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                    <span>Secure Authentication</span>
                  </li>
                  <li className="flex items-center my-1 text-slate-500">
                    <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                    <span>Revenue Tracking</span>
                  </li>
                  <li className="flex items-center my-1 text-slate-500">
                    <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                    <span>Instant Booking</span>
                  </li>
                  <li className="flex items-center my-1 text-slate-500">
                    <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                    <span>Easy Reservation Management</span>
                  </li>
                  <li className="flex items-center my-1 text-slate-500">
                    <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                    <span>User-Friendly Interface</span>
                  </li>
                </ul>
              </div>

              {/* <div className="p-6">
                <div className="pb-8">
                  <h3 className="mb-6 text-xl font-medium dark:text-white">
                    Corporate
                  </h3>
                  <div className="mb-6 dark:text-white/50">
                    <span className="relative text-xl h6 -top-5">$</span>
                    <span className="text-5xl font-medium h6 dark:text-white">
                      49
                    </span>
                    <span className="inline-block h6 ms-1">/ month</span>
                  </div>
                  <p className="mb-6 text-slate-400">
                    Corporate features for up to 100 users.
                  </p>
                  <Link
                    to=""
                    className="inline-block w-full px-5 py-2 text-base font-normal tracking-wide text-center text-white align-middle transition duration-500 ease-in-out border rounded-md bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700"
                  >
                    Signup Now
                  </Link>
                </div>
                <div className="border-b border-slate-200 dark:border-slate-200/5"></div>
                <ul className="self-start pt-8">
                  <li className="flex items-center mb-1 text-slate-400">
                    <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                    <span>Complete documentation</span>
                  </li>
                  <li className="flex items-center my-1 text-slate-400">
                    <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                    <span>Working materials in Figma</span>
                  </li>
                  <li className="flex items-center my-1 text-slate-400">
                    <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                    <span>100GB cloud storage</span>
                  </li>
                  <li className="flex items-center my-1 text-slate-400">
                    <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                    <span>500 team members</span>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>

          <div className="container mt-16 lg:mt-24">
            <div className="grid grid-cols-1 text-center">
              <h3 className="mb-6 text-xl font-medium md:text-2xl">
                Have Question ? Get in touch!
              </h3>

              <p className="max-w-lg mx-auto text-slate-400">
                Your inquiries matter to us! Whether you have questions about
                our services, need assistance with your work, or want to
                learn more about our features, our dedicated support team is
                here to help. Don’t hesitate to reach out, and let us assist you
                in making your experience with us seamless and enjoyable!
              </p>


              <div className="mt-6">
                <Link
                  to="/contactus"
                  className="inline-block px-5 py-2 text-base font-normal tracking-wide text-center text-white align-middle transition duration-500 ease-in-out border rounded-md bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700"
                >
                  <i className="align-middle uil uil-phone me-2"></i> Contact us
                </Link>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </body>
    </>
  );
}
