import React from 'react'
import about from '../assets/images/about.jpg';
import { Link } from "react-router-dom";

export default function About() {
    return (
        <>
            <section className="relative py-16 md:py-24" id="about">
                <div className="container mx-auto">
                    <div className="grid items-center grid-cols-1 gap-10 lg:grid-cols-12 md:grid-cols-2">
                        <div className="lg:col-span-5">
                            <div className="relative">
                                <img src='https://images.pexels.com/photos/3771811/pexels-photo-3771811.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' className="relative shadow-lg" alt="" />
                                {/* <div className="absolute text-center bottom-2/4 translate-y-2/4 end-0 start-0"> */}
                                    {/* <Link to="#!" data-type="youtube" data-id="yba7hPeTSjk"
                                    className="inline-flex items-center justify-center w-20 h-20 bg-white rounded-full shadow-lg lightbox shadow-slate-100 dark:shadow-slate-800 dark:bg-slate-950 text-violet-600">
                                    <i className="inline-flex items-center justify-center text-2xl mdi mdi-play"></i>
                                </Link> */}
                                {/* </div> */}
                            </div>
                        </div>
                        <div className="lg:col-span-7">
                            <div className="lg:ms-7">
                                <h3 className="mb-4 text-xl font-medium md:text-2xl">About Us: Thanayama</h3>

                                <p className="max-w-2xl mx-auto text-slate-400 dark:text-slate-300">Welcome to Thanayam, the ultimate solution for modernizing hotel management. Our platform is designed to help hotels transition from outdated manual processes to an all-in-one digital system that simplifies reservations, automates invoicing, and tracks revenue effortlessly.
                                </p>

                                <div className="relative mt-8">
                                    <Link to="/aboutus"
                                        className="inline-block px-5 py-2 text-base font-normal tracking-wide text-center text-white align-middle transition duration-500 ease-in-out border rounded-md bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700">Read
                                        More <i className="uil uil-arrow-right"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};