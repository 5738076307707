import React from 'react'
import { Link } from "react-router-dom";
import { Hexagon } from 'react-feather';

export default function ContactData() {
    const ContactData = [
        {
            icon: 'uil uil-phone',
            title: 'Phone',
            detail: 'Call us anytime at',
            type: '(+94)70 24 93 211'
        },
        {
            icon: 'uil uil-envelope',
            title: 'Email',
            detail: 'For inquiries, email us at',
            type: 'info@knowebsolutions.com​'
        },
        {
            icon: 'uil uil-map-marker',
            title: 'Location',
            detail: 'No. 422, 4th floor, Sanvik plaza, Wakwella Road, Galle',
            type: ''
        }
    ]
    return (
        <>
            <div className="container mt-16 lg:mt-24">
                <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
                    {ContactData.map((item, index) => (

                        <div className="px-6 text-center" key={index}>
                            <div className="relative -m-3 overflow-hidden text-transparent">
                                <Hexagon className="h-28 w-28 fill-violet-600/5 mx-auto rotate-[30deg]"></Hexagon>
                                <div className="absolute flex items-center justify-center mx-auto text-4xl align-middle transition-all duration-500 ease-in-out top-2/4 -translate-y-2/4 start-0 end-0 text-violet-600 rounded-xl">
                                    <i className={item.icon}></i>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="text-xl font-medium title h5">{item.title}</h5>
                                <p className="mt-3 text-slate-400">{item.detail}</p>

                                <div className="mt-5">
                                    <Link to="tel:+152534-468-854" className="transition duration-500 text-violet-600 hover:text-violet-600 after:bg-violet-600">{item.type}</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
