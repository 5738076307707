import React, { useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";

export default function GetInTouch() {
  const [formData, setFormData] = useState({
    from_email: "",
    subject: "",
    message: "",
  });
  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("sending");

    try {
      const templateParams = {
        from_email: formData.from_email, // Now using the email entered in the form
        // to_email: "knowebtest@gmail.com",
        subject: formData.subject,
        message: formData.message,
        reply_to: formData.from_email, // Using the same email for reply-to
      };

      await emailjs.send(
        "service_dxcw0ok",
        "template_p6tppij",
        templateParams,
        "4U3ijVYVu2Qv-SVTI"
      );

      setStatus("success");
      setFormData({ from_email: "", subject: "", message: "" });
    } catch (error) {
      setStatus("error");
      console.error("Failed to send email:", error);
    }
  };

  return (
    <section
      className="relative py-16 md:py-24 bg-gray-50 dark:bg-slate-800"
      id="contact"
    >
      <div className="container">
        <div className="grid grid-cols-1 pb-8 text-center">
          <h3 className="mb-4 text-xl font-medium md:text-2xl">
            Get In Touch !
          </h3>
          <p className="max-w-lg mx-auto text-slate-400 dark:text-slate-300">
            Your inquiries matter to us! Whether you have questions about our
            services, need assistance with your work, or want to learn more
            about our features, our dedicated support team is here to help.
          </p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-[30px]">
          <div className="lg:col-span-8">
            <div className="p-6 bg-white rounded-md shadow dark:bg-slate-900">
              <form onSubmit={handleSubmit}>
                {status === "success" && (
                  <div className="p-2 mb-4 text-green-700 bg-green-100 rounded">
                    Email sent successfully!
                  </div>
                )}
                {status === "error" && (
                  <div className="p-2 mb-4 text-red-700 bg-red-100 rounded">
                    Failed to send email. Please try again.
                  </div>
                )}
                <div className="grid lg:grid-cols-12 lg:gap-[30px]">
                  <div className="mb-5 lg:col-span-6">
                    <input
                      name="from_email"
                      value={formData.from_email}
                      onChange={handleChange}
                      type="email"
                      className="w-full h-10 px-3 py-2 bg-transparent border rounded outline-none form-input border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0"
                      placeholder="Your Email:"
                      required
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1">
                  <div className="mb-5">
                    <input
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      className="w-full h-10 px-3 py-2 bg-transparent border rounded outline-none form-input border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0"
                      placeholder="Subject:"
                      required
                    />
                  </div>

                  <div className="mb-5">
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      className="w-full px-3 py-2 bg-transparent border rounded outline-none form-input border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 h-28"
                      placeholder="Message:"
                      required
                    ></textarea>
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={status === "sending"}
                  className="inline-block px-5 py-2 text-base font-normal tracking-wide text-center text-white align-middle transition duration-500 ease-in-out border rounded-md bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 disabled:opacity-50"
                >
                  {status === "sending" ? "Sending..." : "Send Message"}
                </button>
              </form>
            </div>
          </div>

          <div className="lg:col-span-4">
            <div className="lg:ms-8">
              <div className="flex">
                <div className="mx-auto text-center icons">
                  <i className="block mb-0 text-2xl rounded uil uil-phone dark:text-white"></i>
                </div>

                <div className="flex-1 ms-6">
                  <h5 className="mb-2 text-lg font-medium dark:text-white">
                    Phone
                  </h5>
                  <Link to="tel:+152534-468-854" className="text-slate-400">
                    (+94)70 24 93 211
                  </Link>
                </div>
              </div>

              <div className="flex mt-4">
                <div className="mx-auto text-center icons">
                  <i className="block mb-0 text-2xl rounded uil uil-envelope dark:text-white"></i>
                </div>

                <div className="flex-1 ms-6">
                  <h5 className="mb-2 text-lg font-medium dark:text-white">
                    Email
                  </h5>
                  <Link
                    to="mailto:contact@example.com"
                    className="text-slate-400"
                  >
                    info@knowebsolutions.com​
                  </Link>
                </div>
              </div>

              <div className="flex mt-4">
                <div className="mx-auto text-center icons">
                  <i className="block mb-0 text-2xl rounded uil uil-map-marker dark:text-white"></i>
                </div>

                <div className="flex-1 ms-6">
                  <h5 className="mb-2 text-lg font-medium dark:text-white">
                    Location
                  </h5>
                  <p className="mb-2 text-slate-400">
                    No. 422, 4th floor, Sanvik plaza, Wakwella Road, Galle
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
