import thanayama from '../assets/images/thanayama.png';
import React from 'react'
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'react-feather';

export default function Login() {
    return (
        <>
            <body className="text-base font-inter text-slate-950 dark:text-white dark:bg-slate-900">

                <section className="position-relative bg-[url('../../assets/images/bg/bg-4.jpg')] bg-center bg-no-repeat">
                    <div className="absolute inset-0 bg-slate-950/50"></div>
                    <div className="relative container-fluid">
                        <div className="grid grid-cols-1">
                            <div className="lg:col-span-4">
                                <div className="flex flex-col min-h-screen px-3 py-12 md:px-12">

                                    <div className="mx-auto text-center">
                                        <Link to="/index"><img src={thanayama} alt="" /></Link>
                                    </div>

                                    <div className="my-auto text-center">
                                        <div className="w-full max-w-sm px-6 py-8 m-auto bg-white rounded-md shadow-lg dark:bg-slate-900 shadow-slate-500 dark:shadow-gray-800">
                                            <div className="grid grid-cols-1">
                                                <h5 className="mb-8 text-xl font-medium dark:text-white">Login</h5>
                                                <form className="text-start">
                                                    <div className="grid grid-cols-1">
                                                        <div className="mb-4">
                                                            <label className="font-medium form-label" htmlFor="LoginEmail">Email Address:</label>
                                                            <input id="LoginEmail" type="email" className="w-full h-10 px-3 py-2 mt-2 bg-transparent border rounded outline-none form-input border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0" placeholder="name@example.com" />
                                                        </div>

                                                        <div className="mb-4">
                                                            <label className="font-medium form-label" htmlFor="LoginPassword">Password:</label>
                                                            <input id="LoginPassword" type="password" className="w-full h-10 px-3 py-2 mt-2 bg-transparent border rounded outline-none form-input border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0" placeholder="Password:" />
                                                        </div>

                                                        <div className="flex justify-between mb-4">
                                                            <div className="flex items-center mb-0">
                                                                <input className="border-gray-200 rounded form-checkbox dark:border-gray-800 text-violet-600 focus:border-violet-300 focus:ring focus:ring-offset-0 focus:ring-violet-200 focus:ring-opacity-50 me-2" type="checkbox" value="" id="RememberMe" />
                                                                <label className="form-checkbox-label text-slate-400" htmlFor="RememberMe">Remember me</label>
                                                            </div>
                                                            <p className="mb-0 text-slate-400"><Link to="/reset-password" className="text-slate-400">Forgot password ?</Link></p>
                                                        </div>

                                                        <div className="mb-4">
                                                            <Link to="/" className="inline-block w-full px-5 py-2 text-base font-normal tracking-wide text-center text-white align-middle transition duration-500 ease-in-out border rounded-md bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700">Login / Sign in</Link>
                                                        </div>

                                                        <div className="text-center">
                                                            <span className="text-slate-400 me-2">Don't have an account ?</span> <Link to="/signup" className="font-bold text-slate-950 dark:text-white">Sign Up</Link>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="text-center">
                                        <p className="text-white/80">© {new Date().getFullYear()} Ovaxo. Design & Develop with <i className="text-red-700 mdi mdi-heart"></i> by <Link to="https://shreethemes.in/" target="_blank" className="text-reset">Shreethemes</Link>.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <div className="fixed bottom-3 end-3">
                    <Link to="" className="inline-flex items-center justify-center text-base font-normal tracking-wide text-center text-white align-middle transition duration-500 ease-in-out border rounded-full back-button h-9 w-9 bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700"><ArrowLeft className="w-4 h-4"></ArrowLeft></Link>
                </div>

              

            </body>
        </>
    )
}
