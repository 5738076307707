
import React from 'react'
import Navbar from '../component/navbar'
import { Link } from 'react-router-dom';
import ClientSreview from '../component/clientsreview';
import Footer from '../component/footer';
import Detail from '../component/detail'
export default function Services() {
    return (
        <>
            <Navbar />
            <section className="py-28 w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-center bg-no-repeat" id="home">
                <div className="absolute inset-0 bg-slate-950/60"></div>

                <div className="container">
                    <div className="grid grid-cols-1 pb-8 mt-10 text-center">
                        <h3 className="mt-10 text-3xl font-medium leading-normal text-white">Our Services</h3>
                    </div>
                </div>
            </section>

            <section className="relative py-16 md:py-24 bg-gray-50 dark:bg-slate-800">
                <div className="container mx-auto lg">
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">

                        <Detail />

                    </div>
                </div>
                {/* <ClientSreview /> */}
                <div className="container mt-16 lg:mt-24">
                    <div className="grid grid-cols-1 text-center">
                        <h3 className="mb-6 text-xl font-medium md:text-2xl">Have Question ? Get in touch!</h3>

                        <p className="max-w-lg mx-auto text-slate-400">
                Your inquiries matter to us! Whether you have questions about
                our services, need assistance with your work, or want to
                learn more about our features, our dedicated support team is
                here to help. Don’t hesitate to reach out, and let us assist you
                in making your experience with us seamless and enjoyable!
              </p>

                        <div className="mt-6">
                            <Link to="/contactus" className="inline-block px-5 py-2 text-base font-normal tracking-wide text-center text-white align-middle transition duration-500 ease-in-out border rounded-md bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700"><i className="align-middle uil uil-phone me-2"></i> Contact us</Link>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </>
    )
}
