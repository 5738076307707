import React from "react";
import OurServices from "../component/our-services";
import MeetOurs from "../component/meet-ours";
import About from "../component/about";
import { Link } from "react-router-dom";
import Navbar from "../component/navbar";
import Footer from "../component/footer";

export default function AboutUs() {
  return (
    <>
      <body className="text-base font-inter text-slate-950 dark:text-white dark:bg-slate-900">
        <Navbar />
        <section
          className="py-28 w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-center bg-no-repeat"
          id="home"
        >
          <div className="absolute inset-0 bg-slate-950/60"></div>

          <div className="container">
            <div className="grid grid-cols-1 pb-8 mt-10 text-center">
              <h3 className="mt-10 text-3xl font-medium leading-normal text-white">
                Our Story
              </h3>
              {/* <span className="mt-10 text-lg font-medium leading-normal text-white">
                At Thanayam, we believe that your travel experience should begin
                with a smooth and hassle-free booking process. Our platform is
                designed to provide you with a range of accommodation options,
                from budget-friendly rooms to luxury suites, all at your
                fingertips. With advanced search features, real-time
                availability, and secure payment gateways, we ensure that
                booking your stay is as convenient as possible. Our customer
                support team is available 24/7 to assist you with any queries or
                special requests, making sure that your stay is tailored to your
                preferences. We are committed to offering competitive rates and
                exclusive deals, ensuring you get the best value for your money.
                At Thanayam, your safety and privacy are our top priorities. We
                use state-of-the-art encryption to protect your personal and
                payment information, giving you peace of mind throughout the
                booking process. Discover the ease and convenience of booking
                with Thanayam and make your next stay unforgettable. Whether
                you're traveling for business or leisure, we've got the perfect
                room waiting for you.
              </span> */}
            </div>
          </div>
        </section>
        <section className="relative py-16 md:py-24" id="about">
          <section className="py-12 bg-gray-900 text-blck">
            <div className="container mx-auto">
              <h2 className="mb-6 text-xl font-semibold text-center md:text-2xl">
                Why Choose Thanayam?
              </h2>
              <p className="mt-10 text-lg font-medium leading-normal text-slate-400 dark:text-slate-300">
                Welcome to Thanayam, the ultimate solution for modernizing hotel
                management. Our platform is designed to help hotels transition
                from outdated manual processes to an all-in-one digital system
                that simplifies reservations, automates invoicing, and tracks
                revenue effortlessly. With Thanayam, you can say goodbye to
                paperwork and focus on providing exceptional guest experiences.
              </p>
              <p className="mt-4 text-lg font-medium leading-normal text-slate-400 dark:text-slate-300">
                At Thanayam, we understand the challenges hotels face in
                managing bookings, billing, and data storage. That's why we’ve
                created an easy-to-use platform that integrates seamlessly into
                your operations, offering real-time booking capabilities, secure
                invoicing, and revenue insights—all in one place. Our system is
                customizable, scalable, and tailored to meet the unique needs of
                hotels, big or small.
              </p>
              <p className="mt-4 text-lg font-medium leading-normal text-slate-400 dark:text-slate-300">
                We’re committed to providing exceptional value by offering
                competitive pricing and ensuring your hotel’s operations are
                streamlined for maximum efficiency. Our top-tier support team is
                always on hand to assist with any questions, ensuring you get
                the most out of our platform. With advanced security measures,
                we safeguard all sensitive data, giving you peace of mind.
              </p>
              <p className="mt-4 text-lg font-medium leading-normal text-slate-400 dark:text-slate-300">
                Join us at Thanayam, and revolutionize your hotel’s operations
                with our cutting-edge platform. It's time to elevate your
                hotel’s management and provide your guests with a seamless,
                modern booking experience.
              </p>
            </div>
          </section>

          <About />
          <OurServices />
          {/* <MeetOurs /> */}
          <div className="container mt-16 lg:mt-24">
            <div className="grid grid-cols-1 text-center">
              <h3 className="mb-6 text-xl font-medium md:text-2xl">
                Have Question ? Get in touch!
              </h3>

              <p className="max-w-lg mx-auto text-slate-400">
                Your inquiries matter to us! Whether you have questions about
                our services, need assistance with your work, or want to
                learn more about our features, our dedicated support team is
                here to help. Don’t hesitate to reach out, and let us assist you
                in making your experience with us seamless and enjoyable!
              </p>

              <div className="mt-6">
                <Link
                  to="/contactus"
                  className="inline-block px-5 py-2 text-base font-normal tracking-wide text-center text-white align-middle transition duration-500 ease-in-out border rounded-md bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700"
                >
                  <i className="align-middle uil uil-phone me-2"></i> Contact us
                </Link>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </body>
    </>
  );
}
