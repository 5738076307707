import React from 'react'
import {useNavigate} from 'react-router-dom'
import { Link } from "react-router-dom";

export default function ComfortablePricing() {
    const handleRedirect = () => {
        // Replace with the URL of the other project
        window.location.href = "http://localhost:5173/login";
      };

      const navigate = useNavigate();
      const handleSignup =()=>{
        navigate('/signup')
      }
    return (
        <>
            <section  className="relative py-16 md:py-24" id="pricing">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 text-xl font-medium md:text-2xl">Our Comfortable Pricing</h3>

                        <p className="max-w-xl mx-auto text-slate-400 dark:text-slate-300">Explore our comfortable pricing options designed to provide value and flexibility for every budget</p>
                    </div>

                    <div className="grid md:grid-cols-3 grid-cols-1 mt-8 gap-[30px] items-center">
                    <div className="p-6 rounded-md shadow-md border border-slate-500 hover:border-violet-600">
                <div className="pb-8">
                  <h3 className="mb-6 text-xl font-medium text-slate-600">
                    Business
                  </h3>
                  <div className="mb-6 text-white/50">
                    <span className="relative text-xl h6 -top-5 text-slate-600">$</span>
                    <span className="text-5xl font-bold text-slate-600 h6">9.99</span>
                    <span className="inline-block h6 ms-1 text-slate-600">/ month</span>
                  </div>
                  <p className="mb-6 text-white">
                    Basic features for your place
                  </p>
                  <Link
                    to="/signup"
                    className="inline-block w-full px-5 py-2 text-base font-normal tracking-wide text-center text-white align-middle transition duration-500 ease-in-out bg-violet-600 border border-violet-600 rounded-md hover:bg-violet-700 hover:border-violet-700"
                  >
                    Started Now
                  </Link>
                </div>
                <div className="border-b border-slate-200/10"></div>
                <ul className="self-start pt-8">
                  <li className="flex items-center mb-1 text-slate-500">
                    <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                    <span>Quick Billing</span>
                  </li>
                  <li className="flex items-center my-1 text-slate-500">
                    <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                    <span>Secure Authentication</span>
                  </li>
                  <li className="flex items-center my-1 text-slate-500">
                    <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                    <span>Revenue Tracking</span>
                  </li>
                  <li className="flex items-center my-1 text-slate-500">
                    <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                    <span>Instant Booking</span>
                  </li>
                  <li className="flex items-center my-1 text-slate-500">
                    <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                    <span>Easy Reservation Management</span>
                  </li>
                  <li className="flex items-center my-1 text-slate-500">
                    <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                    <span>User-Friendly Interface</span>
                  </li>
                </ul>
              </div>

                        {/* <div className="p-6">
                            <div className="pb-8">
                                <h3 className="mb-6 text-xl font-medium dark:text-white">Free</h3>
                                <div className="mb-6 dark:text-white/50">
                                    <span className="relative text-xl h6 -top-5">$</span>
                                    <span className="text-5xl font-medium h6 dark:text-white">00</span>
                                    <span className="inline-block h6 ms-1">/ month</span>
                                </div>
                                <p className="mb-6 text-slate-400">Free features for your business.</p>
                                <Link to=""
                                    className="inline-block w-full px-5 py-2 text-base font-normal tracking-wide text-center align-middle transition duration-500 ease-in-out border rounded-md bg-violet-600/5 hover:bg-violet-600 border-violet-600/10 hover:border-violet-600 text-violet-600 hover:text-white">Redeem
                                    Now</Link>
                            </div>
                            <div className="border-b border-slate-200 dark:border-slate-200/5"></div>
                            <ul className="self-start pt-8">
                                <li className="flex items-center mb-1 text-slate-400">
                                    <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                                    <span>Complete documentation</span>
                                </li>
                                <li className="flex items-center my-1 text-slate-400">
                                    <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                                    <span>Working materials in Figma</span>
                                </li>
                                <li className="flex items-center my-1 text-slate-400">
                                    <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                                    <span>100GB cloud storage</span>
                                </li>
                                <li className="flex items-center my-1 text-slate-400">
                                    <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                                    <span>500 team members</span>
                                </li>
                            </ul>
                        </div> */}

                        {/* <div className="p-6">
                            <div className="pb-8">
                                <h3 className="mb-6 text-xl font-medium dark:text-white">Corporate</h3>
                                <div className="mb-6 dark:text-white/50">
                                    <span className="relative text-xl h6 -top-5">$</span>
                                    <span className="text-5xl font-medium h6 dark:text-white">49</span>
                                    <span className="inline-block h6 ms-1">/ month</span>
                                </div>
                                <p className="mb-6 text-slate-400">Corporate features for up to 100 users.</p>
                                <Link to=""
                                    className="inline-block w-full px-5 py-2 text-base font-normal tracking-wide text-center text-white align-middle transition duration-500 ease-in-out border rounded-md bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700">Signup
                                    Now</Link>
                            </div>
                            <div className="border-b border-slate-200 dark:border-slate-200/5"></div>
                            <ul className="self-start pt-8">
                                <li className="flex items-center mb-1 text-slate-400">
                                    <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                                    <span>Complete documentation</span>
                                </li>
                                <li className="flex items-center my-1 text-slate-400">
                                    <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                                    <span>Working materials in Figma</span>
                                </li>
                                <li className="flex items-center my-1 text-slate-400">
                                    <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                                    <span>100GB cloud storage</span>
                                </li>
                                <li className="flex items-center my-1 text-slate-400">
                                    <i className="text-xl text-green-600 uil uil-check-circle me-2"></i>
                                    <span>500 team members</span>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}
