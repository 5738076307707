import React from "react";
import Footer from "../component/footer";
import GetInTouch from "../component/get-in-touch";
import BlogsNews from "../component/blogs-news";
import ClientSreview from "../component/clientsreview";
import ComfortablePricing from "../component/comfortable-pricing";
import OurServices from "../component/our-services";
import About from "../component/about";
import cta from "../assets/images/cta.jpg";
import { Link } from "react-router-dom";
import NavbarSmallLight from "../component/navbar-small-light";

export default function Index() {
  return (
    <>
      <NavbarSmallLight />
      <section
        className="py-36 lg:py-56 w-full table relative bg-[url('../../assets/images/bg/bg.jpg')] bg-center bg-no-repeat"
        id="home"
      >
        <div className="absolute inset-0 bg-gradient-to-t to-slate-950/50 via-slate-950/75 from-slate-950"></div>
        <div className="container">
          <div className="grid grid-cols-1 pb-8 mt-10 text-center">
            <h3 className="mt-10 mb-5 text-4xl font-medium leading-normal text-white">
              Elevate Your Property with Seamless Digital Reservation and
              Invoicing
            </h3>
            <p className="max-w-3xl text-lg mx-aut4o text-slate-400">
              Transform the guest experience with a platform that streamlines
              reservations, automates elegant invoicing, and delivers effortless
              revenue insights. Leave the paperwork behind and step into the
              future of hospitality management!
            </p>
            <div className="mt-8">
              <button
                onClick={() =>
                  document
                    .getElementById("pricing")
                    .scrollIntoView({ behavior: "smooth" })
                }
                className="inline-block px-5 py-2 text-base font-normal tracking-wide text-center text-white align-middle transition duration-500 ease-in-out border rounded-md border-violet-600 bg-violet-600 hover:bg-violet-700 hover:border-violet-700"
              >
                <i className="uil uil-rocket"></i> Getting Start
              </button>
            </div>
          </div>
        </div>
      </section>
      <About />
      <OurServices />
      <section className="relative py-16 pt-0 md:py-24 md:pt-0 bg-gray-50 dark:bg-slate-800">
        <div className="container">
          <div className="grid justify-center grid-cols-1">
            <div className="relative z-1">
              <div className="grid justify-center grid-cols-1 text-center lg:grid-cols-12 md:text-start">
                <div className="lg:col-start-2 lg:col-span-10">
                  <div className="relative">
                    {/* Replace lightbox video section with a photo */}
                    <img
                      src={cta}
                      className="rounded-md shadow-lg"
                      alt="Empowering Businesses"
                    />
                  </div>
                </div>
              </div>
              <div className="content md:mt-8">
                <div className="grid justify-center grid-cols-1 text-center lg:grid-cols-12 md:text-start">
                  <div className="lg:col-start-2 lg:col-span-10">
                    <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-8 gap-[30px]">
                      <div>
                        <div className="section-title text-md-start">
                          <h6 className="text-white/50">Service Offerings</h6>
                          <h3 className="mt-2 text-xl font-medium text-white md:text-2xl">
                            Versatile Solutions for
                            <br /> Every Establishment's Unique Needs
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 end-0 start-0 h-4/5 md:h-2/3 bg-violet-600"></div>
      </section>
      <ComfortablePricing />

      <GetInTouch />
      <Footer />
    </>
  );
}
