import thanayama from '../assets/images/thanayama.jpg';
import React from 'react'
import { Link } from "react-router-dom";

export default function Footer() {

    return (
        <>
            <footer className="relative text-gray-200 footer bg-slate-950 dark:text-gray-200">
                <div className="container">
                    <div className="grid grid-cols-12">
                        <div className="col-span-12">
                            <div className="py-[60px] px-0">
                                <div className="grid grid-cols-1">
                                    <div className="text-center">
                                        <img src={thanayama} className="block mx-auto w-36 h-28" alt="" />
                                        <p className="max-w-xl mx-auto mt-8 text-slate-400">Discover a Range of Exceptional Services Tailored to Enhance Your Experience
                                        </p>
                                    </div>

                                    <ul className="mt-8 text-center list-none footer-list">
                                        <li className="inline px-2"><Link to="/services"
                                            className="text-gray-300 duration-500 ease-in-out hover:text-gray-400">Services</Link>
                                        </li>
                                        <li className="inline px-2 mt-[10px]"><Link to="/aboutus"
                                            className="text-gray-300 duration-500 ease-in-out hover:text-gray-400 ms-2">About
                                            Us</Link></li>
                                        {/* <li className="inline px-2 mt-[10px]"><Link to="/blogs"
                                            className="text-gray-300 duration-500 ease-in-out hover:text-gray-400 ms-2">Blogs</Link>
                                        </li>
                                        <li className="inline px-2 mt-[10px]"><Link to="/team"
                                            className="text-gray-300 duration-500 ease-in-out hover:text-gray-400 ms-2">Team</Link>
                                        </li> */}
                                        <li className="inline px-2 mt-[10px]"><Link to="/pricing"
                                            className="text-gray-300 duration-500 ease-in-out hover:text-gray-400 ms-2">Pricing</Link>
                                        </li>
                                        <li className="inline px-2 mt-[10px]"><Link to="/contactus"
                                            className="text-gray-300 duration-500 ease-in-out hover:text-gray-400 ms-2">Contact
                                            Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="py-[30px] px-0 border-t border-slate-800">
                    <div className="container text-center">
                        <div className="grid items-center md:grid-cols-12">
                            <div className="md:col-span-6">
                                <div className="text-center md:text-start">
                                    <p className="text-gray-400">©
                                        {new Date().getFullYear()} Thanayama. Design & Develop with  by <Link to="https://knowebsolutions.com/"
                                                target="_blank" className="text-reset">knowebsolutions</Link>.
                                    </p>
                                </div>
                            </div>

                            <div className="mt-8 md:col-span-6 md:mt-0">
                                <ul className="text-center list-none md:text-end">
                                    {/* <li className="inline"><Link to="https://1.envato.market/ovaxo-react" target="_blank"
                                        className="inline-flex items-center justify-center w-8 h-8 text-base font-normal tracking-wide text-center align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i
                                            className="align-middle uil uil-shopping-cart" title="Buy Now"></i></Link></li>
                                    <li className="inline ms-1"><Link to="https://dribbble.com/shreethemes" target="_blank"
                                        className="inline-flex items-center justify-center w-8 h-8 text-base font-normal tracking-wide text-center align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i
                                            className="align-middle uil uil-dribbble" title="dribbble"></i></Link></li>
                                    <li className="inline ms-1"><Link to="https://www.behance.net/shreethemes" target="_blank"
                                        className="inline-flex items-center justify-center w-8 h-8 text-base font-normal tracking-wide text-center align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i
                                            className="uil uil-behance" title="Behance"></i></Link></li> */}
                                    <li className="inline ms-1"><Link to="https://www.linkedin.com/company/knowebsolutions/" target="_blank"
                                        className="inline-flex items-center justify-center w-8 h-8 text-base font-normal tracking-wide text-center align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i
                                            className="uil uil-linkedin" title="Linkedin"></i></Link></li>
                                    <li className="inline ms-1"><Link to="https://www.facebook.com/profile.php?id=61563836600897" target="_blank"
                                        className="inline-flex items-center justify-center w-8 h-8 text-base font-normal tracking-wide text-center align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i
                                            className="align-middle uil uil-facebook-f" title="facebook"></i></Link></li>
                                    {/* <li className="inline ms-1"><Link to="https://www.instagram.com/shreethemes/" target="_blank"
                                        className="inline-flex items-center justify-center w-8 h-8 text-base font-normal tracking-wide text-center align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i
                                            className="align-middle uil uil-instagram" title="instagram"></i></Link></li>
                                    <li className="inline ms-1"><Link to="https://twitter.com/shreethemes" target="_blank"
                                        className="inline-flex items-center justify-center w-8 h-8 text-base font-normal tracking-wide text-center align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i
                                            className="align-middle uil uil-twitter" title="twitter"></i></Link></li> */}
                                    <li className="inline ms-1"><Link to="info@knowebsolutions.com​"
                                        className="inline-flex items-center justify-center w-8 h-8 text-base font-normal tracking-wide text-center align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i
                                            className="align-middle uil uil-envelope" title="email"></i></Link></li>
                                    {/* <li className="inline ms-1"><Link to="https://forms.gle/QkTueCikDGqJnbky9" target="_blank"
                                        className="inline-flex items-center justify-center w-8 h-8 text-base font-normal tracking-wide text-center align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i
                                            className="align-middle uil uil-file" title="customization"></i></Link></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
