import React from 'react';
import { Link } from "react-router-dom";
import { Hexagon } from 'react-feather';

export default function Detail() {
    const services = [
        {
            icon: 'adjust-circle',
            title: 'Fast and Simple Reservations',
            description: "Simplify the booking process with instant availability checks and quick booking confirmations. Guests can reserve their rooms in seconds.",
        },
        {
            icon: 'circuit',
            title: 'Protected Access',
            description: "Our platform provides robust, secure login and authentication protocols, giving your guests peace of mind that their personal data and booking details are safe.",
        },
        {
            icon: 'fire',
            title: 'Real-Time Revenue Insights',
            description: " Easily monitor your business performance with built-in revenue tracking. Access detailed financial reports and make data-driven decisions to boost your profitability",
        },
        {
            icon: 'flower',
            title: 'View and Manage Reservations',
            description: "Stay on top of your bookings with an intuitive reservation dashboard. Quickly view and manage guest bookings, keeping operations smooth and organized.",
        },
        {
            icon: 'shopping-basket',
            title: 'Seamless Notifications',
            description: "Stay informed with automated notifications for upcoming reservations, billing alerts, and important updates ensuring you never miss a beat in managing your hotel.",
        },
        {
            icon: 'flip-h',
            title: 'Intuitive Design',
            description: "Our platform is designed with simplicity and ease of use in mind. Whether on mobile or desktop, your guests and staff will enjoy a seamless, hassle-free experience.",
        }
    ];


    return (
        <>
            {services.map((service, index) => (
                <div key={index} className="relative mt-4 overflow-hidden text-center transition duration-500 ease-in-out group lg:px-6 rounded-xl">
                    <div className="relative -m-3 overflow-hidden text-transparent">
                        <Hexagon className="h-28 w-28 fill-violet-600/5 mx-auto rotate-[30deg]"
                        ></Hexagon>
                        <div className="absolute flex items-center justify-center mx-auto text-3xl align-middle transition duration-500 ease-in-out top-2/4 -translate-y-2/4 start-0 end-0 text-violet-600 rounded-xl">
                            <i className={`uil uil-${service.icon}`}></i>
                        </div>
                    </div>
                    <div className="mt-6">
                        <Link to="/services" className="text-lg transition duration-500 ease-in-out h5 hover:text-violet-600" >
                            {service.title}
                        </Link>
                        <p className="mt-3 transition duration-500 ease-in-out text-slate-400">
                            {service.description}
                        </p>
                    </div>
                </div>
            ))}
        </>
    )
}
